<template>
  <div class="topbar">
    <div class="container">
      <div class="searchbox">
        <form>
          <input
            class="key"
            type="text"
            placeholder="Enter the item code or name to search"
            ref="input"
            v-model="search"
          />
          <button class="icon">
            <i class="el-icon-search" @click="tosearch"></i>
          </button>
        </form>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "PcWebsiteSiteHeader",

  data() {
    return {
      search: "",
    };
  },

  mounted() {},
  watch: {},
  methods: {
    tosearch() {
      this.$emit("tosearch", this.search);
    },
  },
};
</script>

<style lang="scss" scoped></style>
