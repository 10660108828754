<template>
  <div>
    <SiteHeader></SiteHeader>

    <SiteSearch @tosearch="tosearch">
      <div class="detailNav">
        <li >
          <a v-if="prev" @click="toPrev(prev.id)"><i class="bi bi-arrow-90deg-left"></i>{{ prev.title }}</a>
        </li>
        <li >
          <a v-if="next" @click="toNext(next.id)">{{ next.title }} <i class="bi bi-arrow-90deg-right"></i></a>
        </li>
      </div>
    </SiteSearch>

    <div class="container d-flex justify-content-center">
      <div class="col-md-3 menuBar">
        <div class="bs-docs-sidebar hidden-xs hidden-sm">
          <ul class="bs-docs-sidenav">
            <li
              :class="{ parentli: true, active: nav.checked }"
              v-for="nav in category"
              :key="nav.id"
            >
              <a @click="toCateList(nav.id)">{{ nav.ename }}</a>
              <ul class="child ps-0" v-if="nav.children.length > 0">
                <li
                  :class="{ childli: true, active: item.checked }"
                  v-for="item in nav.children"
                  :key="item.id"
                >
                  <a @click="toCateList(item.id)">{{ item.ename }}</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-9" role="main">
        <div class="proinfo mb-2">
          <div class="preview col-md-6">
            <img :src="detail.thumb" />
          </div>
          <div class="col-md-6">
            <div class="infomation psrel">
              <div class="ribbon">
                <abbr class="ribbon-child">
                  {{ detail.itemType }}
                </abbr>
              </div>
              <h3>{{ detail.title }}</h3>
              <p class="mt-3">
                <span class="item"
                  ><i class="bi-front text-dark"></i>
                  <div class="righttext">Series:{{ detail.series }}</div></span
                >
                <span class="item"
                  ><i class="bi-vinyl text-dark"></i>
                  <div class="righttext">
                    CasePack:{{ detail.casepack }} pc
                  </div></span
                >
              </p>
              <p>
                <span class="item"
                  ><i class="bi-upc-scan text-dark"></i>
                  <div class="righttext">Code:{{ detail.code }}</div></span
                >
                <span class="item"
                  ><i class="bi-broadcast text-dark"></i>
                  <div class="righttext">Dash:{{ detail.dash }}</div></span
                >
              </p>
              <p>
                <span class="item"
                  ><i class="bi bi-box-seam"></i>
                  <div class="righttext">{{ detail.itemType }}</div>
                </span>

                <span class="item">
                  <i class="bi bi-link-45deg text-dark"></i>
                  <div class="righttext">{{ detail.country }}</div>
                </span>
              </p>

              <p>
                <span class="text-uppercase item" style="width: 100%"
                  ><i class="bi bi-calendar2-check-fill"></i>
                  <div class="righttext">
                    quantity is subject to finality
                  </div></span
                >
              </p>

              <p>
                <span style="width: 100%" class="item">
                  <i class="bi-calendar3 text-dark"></i>
                  <div class="righttext">
                    Delivery ETA：{{ detail.ship_at }}
                  </div></span
                >
              </p>

              <p>
                <span class="item">
                  <i class="bi bi-capslock text-dark"></i>
                  <div class="righttext">MOQ：{{ detail.moq }}</div>
                </span>
                <span class="item" v-if="detail.max"
                  ><i class="bi-x-diamond text-dark"></i>
                  <div class="righttext">MAX:{{ detail.max }}</div></span
                >
              </p>

              <p>
                <span style="width: 100%" class="item">
                  <i class="bi bi-cash text-dark"></i>
                  <div v-if="!token" class="righttext">Login to see price</div>
                  <div
                    v-else
                    class="righttext text-danger"
                    style="font-weight: bold"
                  >
                    {{ Number(detail.price * (detail.discount || 1)).toFixed(2) }} USD/PC
                  </div>
                </span>
              </p>

              <div class="linebox cart-area" v-if="detail.mask === 1">
                <span class="shop-add">
                  <button
                    class="car-reduce btn btn-secondary"
                    :disabled="reduceDisabled"
                    @click="reduce"
                  >
                    <i class="bi-dash"></i>
                  </button>

                  <!-- <input type="text" class="car-nums btn btn-outline-secondary" v-model="count" /> -->
                  <input
                    type="text"
                    class="car-nums btn btn-outline-secondary"
                    @input="handleInput($event)"
                    @blur="changeCount(detail, $event.target.value)"
                    :value="count"
                  />
                  <button
                    class="car-add btn btn-secondary"
                    :disabled="plusDisabled"
                    @click="plus"
                  >
                    <i class="bi-plus"></i>
                  </button>
                </span>
              </div>

              <div class="linebox cart-area last mb-0 clearfix">
                <span v-if="detail.mask === 1" class="f_right">
                  <section
                    class="btn btn-outline-danger ajax-submit"
                    @click="addCart"
                    unselectable="on"
                    onselectstart="return false;"
                  >
                    Add to Cart
                  </section>
                </span>

                <section
                  v-if="detail.mask === 2"
                  class="btn btn-warning"
                  style="width: 100%"
                >
                  Closed
                </section>

                <section
                  v-if="detail.mask === 3"
                  class="btn btn-warning"
                  style="width: 100%"
                >
                  Sold Out
                </section>
              </div>
            </div>
          </div>
        </div>

        <div class="description px-2">
          <h3>Detalied Descriptiomn</h3>

          <div class="content mb-3" v-html="detail.content"></div>

          <!-- <h3 class="psrel">More Images<a class="btn btn-outline-danger btn-sm psabs" style="right:0px; top:-5px;" >Download</a></h3> -->

          <!-- <p v-if="detail.pics.length === 0">We have access for members only</p> -->

          <div class="content">
            <li v-for="item in detail.pics"><img :src="item" /></li>
          </div>
        </div>
      </div>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import SiteHeader from "@/components/website/SiteHeader.vue";
import SiteSearch from "@/components/website/SiteSearch.vue";
import Foot from "@/components/website/Foot.vue";

import { detail } from "@/api/index.js";

export default {
  name: "PcWebsiteIndex",

  components: {
    SiteHeader,
    SiteSearch,
    Foot,
  },

  data() {
    return {
      plusDisabled: false,
      reduceDisabled: true,
      count: 1000,
      id: null,
      detail: {
        count: null,
      },
      next: {
        id: null,
        title: "1",
      },
      prev: {
        id: null,
        title: "1",
      },
      search: "",
    };
  },
  computed: {
    token: {
      get() {
        return this.$store.state.user.token;
      },
    },
    userinfo: {
      get() {
        return this.$store.state.user.userinfo;
      },
    },
  },

  watch: {
    $route(to, from) {
      this.id = this.$route.params.id;
      this.getDetail();
    },
  },
  mounted() {
    this.id = this.$route.params.id;
    this.getDetail();
  },
  methods: {
    tosearch(search) {
      this.$router.push("/allproducts?search=" + search);
    },

    handleInput(event) {
      this.count = event.target.value; // 实时更新数据模型
      this.detail.count = event.target.value;
      this.$store.commit("user/addCart", this.detail);
    },

    changeCount(item, value) {
      value = parseInt(value);
      const casepack = item.casepack;
      if (this.detail.max === 0) {
        this.detail.max = this.detail.stock;
      }
      const max = item.max < item.stock ? item.max : item.stock;
      // const max =  item.stock
      const moq = item.moq;
      // console.log('value',value,'max',max,'moq',moq,'casepack',casepack)

      let newvalue = item.moq;
      if (value <= parseInt(moq)) {
        newvalue = moq;
        this.$nextTick(() => {
          this.plusDisabled = false;
          this.reduceDisabled = true;
        });
      }
      if (value >= max) {
        newvalue = max;
        this.$nextTick(() => {
          this.plusDisabled = true;
          this.reduceDisabled = false;
        });
      }
      if (moq < value && value < max) {
        newvalue = Math.floor((value - moq) / casepack) * casepack + moq;
        this.$nextTick(() => {
          this.plusDisabled = false;
          this.reduceDisabled = false;
        });
      }

      item.count = newvalue;
      this.count = newvalue;
    },

    plus() {
      // console.log(this.detail)
      if (this.detail.max === 0) {
        this.detail.max = this.detail.stock;
      }

      if (this.count < this.detail.max) {
        this.$nextTick(() => {
          this.count = this.count + this.detail.casepack;
          if (this.count >= parseInt(this.detail.stock)) {
            this.count = parseInt(this.detail.stock);
          }
          this.reduceDisabled = false;
        });
      } else {
        this.$nextTick(() => {
          this.plusDisabled = true;
          this.reduceDisabled = false;
        });
      }
    },
    reduce() {
      // console.log('---',this.detail.moq,this.count)
      if (this.count > this.detail.moq) {
        this.$nextTick(() => {
          this.count = this.count - this.detail.casepack;
          this.plusDisabled = false;
          this.reduceDisabled = false;
        });
      } else {
        this.$nextTick(() => {
          this.plusDisabled = false;
          this.reduceDisabled = true;
        });
      }
    },

    addCart() {
      this.detail.count = this.count;
      this.$store.commit("user/addCart", this.detail);
      this.$message({
        message: "Add to cart successfully",
        type: "success",
      });
    },
    toPrev(id) {
      this.$router.push("/detail/" + id);
    },
    toNext(id) {
      this.$router.push("/detail/" + id);
    },
    toCateList(id) {
      this.$router.push("/allproducts?cid=" + id);
    },
    getDetail() {
      detail({ id: this.id }).then((res) => {
        this.category = res.data.category_tree;
        this.detail = res.data.detail;
        this.count = res.data.detail.moq;
        this.prev = res.data.prev;
        this.next = res.data.next;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  .righttext {
    display: inline-block;
    margin-left: 5px;
    position: relative;
    top: -1px;
  }
}
</style>
